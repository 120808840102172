// 加密JS
import CryptoJS from 'crypto-js'

/**
 * 接口数据加密函数
 * @param str string 需加密的json字符串
 * @param keyHash string 加密keyHash(16位)
 * @param iv string 加密向量(16位)
 * @return string 加密密文字符串
 */
export const fstEncrypt = (str, keyHash, _iv) => {
  if (!str) {
    return str
  }
  // 密钥
  const key = CryptoJS.enc.Utf8.parse(keyHash)
  // 加密向量
  const iv = CryptoJS.enc.Utf8.parse(_iv)
  const encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

/**
 * 接口数据解密函数
 * @param str string 已加密密文
 * @param keyHash string 加密keyHash
 * @param iv string 加密向量
 * @returns {*|string} 解密之后的json字符串
 */
export const fstDecrypt = (str, keyHash, _iv) => {
  if (!str) {
    return str
  }
  // 密钥
  const key = CryptoJS.enc.Utf8.parse(keyHash)
  // 加密向量
  const iv = CryptoJS.enc.Utf8.parse(_iv)
  const decrypted = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(str), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let decryptedObj = CryptoJS.enc.Utf8.stringify(decrypted)
  if (decryptedObj) {
    decryptedObj = JSON.parse(decryptedObj)
    return decryptedObj
  } else {
    return str
  }
}

// 打印结果： ebcebcebc
