// 登录注册弹框

export default {
  props: {
    loginVisible: {
      require: true,
      type: Boolean,
      default: false
    }
  }
}
