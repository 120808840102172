import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global from './config'
import VueWechatTitle from 'vue-wechat-title'
import { fstDecrypt, fstEncrypt } from '@/utils/cryptojs'
import filters from './utils/filters'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$Global = global
Vue.use(VueWechatTitle)
// 全局导入过滤器
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]))
Vue.prototype.fstDecrypt = fstDecrypt // 数据解密
Vue.prototype.fstEncrypt = fstEncrypt // 数据加密函数
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
