import axios from 'axios'
import { Notification } from 'element-ui'
import global from '../config'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
// 清除所有缓存数据
// import { tologin } from "@/utils/toLogin";
import { fstDecrypt, fstEncrypt } from '@/utils/cryptojs'
const service = axios.create({
  baseURL: global.baseUrl, // api 的 base_url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 240000 // request timeout
})
let encryptArr = ['/env/work/env/queryConfig']
if (localStorage.localEncrypt) {
  encryptArr = JSON.parse(localStorage.localEncrypt)
}

// 请求头
service.interceptors.request.use(
  async (config) => {
    if (!localStorage.uniCode) {
      const fp = await FingerprintJS.load()
      const mycode = await fp.get()
      localStorage.uniCode = mycode.visitorId
    }
    const randomNumber = window.apiNewRequestHeader(localStorage.uniCode)
    config.headers.request_unique_id = randomNumber
    config.headers.equipmentNumber = localStorage.uniCode // 设备号
    config.headers.gray_header = global.serveCode // 后端版本号
    config.headers.appName = global.appName
    config.headers.platform = 'web' // 当前是什么端
    const tampUrl = config.url.indexOf('/') === 0 ? config.url : '/' + config.url
    // tampUrl = tampUrl.replace(/\//g, '')
    // 把所有的/都去掉之后匹配
    if (encryptArr.includes(tampUrl)) {
      if (config.data && JSON.stringify(config.data) !== '{}') {
        // 加密body的参数
        config.data = {
          encrypt: fstEncrypt(
            JSON.stringify(config.data),
            global.$aesKey,
            global.$aesIv
          )
        }
      }
      if (config.params && JSON.stringify(config.params) !== '{}') {
        // 加密query的参数
        config.params = {
          encrypt: fstEncrypt(
            JSON.stringify(config.params),
            global.$aesKey,
            global.$aesIv
          )
        }
      }
    }
    // var timestamp = new Date().getTime();
    // var expireTime = Number(window.localStorage.expireTime); // 超时的时间
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'

    let APIhead
    let webToken = ''
    if (localStorage.getItem('zhiHuiXinZhouwebToken')) {
      webToken = JSON.parse(
        localStorage.getItem('zhiHuiXinZhouwebToken')
      )
    }
    // 有密钥走加密
    if (localStorage.APIencipher) {
      const tamp = fstDecrypt(
        localStorage.getItem('APIencipher'),
        global.$aesKey,
        global.$aesIv
      )
      APIhead = window.APIjudgment(
        config,
        randomNumber,
        global.appName || '',
        webToken,
        tamp
      )
    } else if (window.localStorage.zhiHuiXinZhouwebToken) {
      // 无密钥有token
      APIhead = window.APItoken(webToken)
    }
    if (APIhead && JSON.stringify(APIhead) !== '{}') {
      for (const key in APIhead) {
        config.headers[key] = APIhead[key]
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 请求响应拦截
service.interceptors.response.use(
  (response) => {
    // console.log(response, ">>>请求响应拦截");
    // 接口请求成功
    if (response.data.code === 403) {
      localStorage.removeItem('zhiHuiXinZhouwebToken')
    } else if (
      Object.prototype.hasOwnProperty.call(response.headers, 'isencrypt') &&
      response.data.success
    ) {
      const { success, code, message } = { ...response.data }
      const ecryptData = fstDecrypt(
        response.data.data,
        global.$aesKey,
        global.$aesIv
      )
      return {
        success,
        code,
        data: ecryptData,
        message
      }
    } else {
      return response.data
    }
  },
  (error) => {
    // 接口请求错误
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          showClose: true,
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (code) {
      if (code === 403) {
        localStorage.removeItem('zhiHuiXinZhouwebToken')
        // console.log("我的主应用");
        // Vue.prototype.setGlobalState({
        //   linkstatus: 200,
        // });
        // tologin(); // 跳转登录页面或者其他页面
      }
      // else {
      //   const errorMsg = error.response.data.message;
      //   if (errorMsg !== undefined) {
      //     Notification.error({
      //       title: errorMsg,
      //       duration: 5000,
      //     });
      //   } else {
      //     Notification.error({
      //       title: "不好意思，出小差了",
      //       duration: 5000,
      //     });
      //   }
      // }
    }
    // else {
    //   Notification.error({
    //     title: "不好意思，出小差了",
    //     duration: 5000,
    //   });
    // }
    return Promise.reject(error)
  }
)

export default service
