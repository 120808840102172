<template>
  <div class="myloginsData">
    <el-dialog :visible="loginVisible" width="374px" top="15%" @close="changeParentStatus">
      <div class="loingHead">
        <div class="loingTitle">登录注册</div>
        <div class="loingTitleZhanwei"></div>
        <div class="loingTitleYanzhen">验证码登录</div>
        <div class="loingTitleZhanweiTwo"></div>

        <input
          oninput="value=value.replace(/[^\d]/g,'')"
          type="text"
          id="loingTitleZhanweiTwoPhone"
          class="loingTitleZhanweiTwoPhone"
          placeholder="请输入手机号"
          autocomplete="off"
        />

        <div class="loingTitleZhanweiTwoCode">
          <input
            oninput="value=value.replace(/[^\d]/g,'')"
            type="text"
            id="loingTitleZhanweiTwoCodeOne"
            class="loingTitleZhanweiTwoCodeOne"
            placeholder="请输入验证码"
            autocomplete="off"
          />

          <el-button class="codebutton" id="codebutton" :disabled="dis" @click="sendcodes">发送验证码</el-button>
        </div>
        <el-button class="codebuttonLogin" @click="login">登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import home from '@/api/index.js'
import props from './props.js'
export default {
  mixins: [props],
  data () {
    return {
      dis: false
    }
  },
  methods: {
    changeParentStatus () {
      this.$emit('changeLoginVisible')
    },
    // 登录
    login () {
      // const logoOut = document.getElementById('serchHeadLogin')
      // const logoIn = document.getElementById('serchHeadLoginOut')
      const phone = document.getElementsByClassName(
        'loingTitleZhanweiTwoPhone'
      )
      // 验证码
      const code = document.getElementsByClassName(
        'loingTitleZhanweiTwoCodeOne'
      )
      if (phone[0].value && code[0].value) {
        const data = {
          userName: phone[0].value,
          appName: this.$store.state.$config.appName,
          code: code[0].value
        }
        home.getMloginLoginMember(data).then(res => {
          if (res.success) {
            localStorage.setItem(
              'zhiHuiXinZhouwebToken',
              JSON.stringify(res.data.token)
            )
            this.$store.dispatch(
              'actionSetToken',
              JSON.stringify(res.data.token)
            )
            const data = {
              appName: this.$store.state.$config.appName
            }
            home.getMloginToMember(data).then(reas => {
              if (reas.success) {
                localStorage.setItem(
                  'zhiHuiXinZhouwebUserInfo',
                  JSON.stringify(reas.data)
                )
                this.$store.dispatch(
                  'actionSetUserInfo',
                  JSON.stringify(reas.data)
                )
                this.$message({
                  message: '登录成功',
                  type: 'success'
                })
                this.changeParentStatus()
                // logoInClassName[0].innerHTML = `<span style="color: #1e63b0">${reas.data.alias}&nbsp;</span> / <span onclick="signOut()">退出&nbsp;</span>`;
                this.logoUser = reas.data.alias
                // logoOut.style.display = 'none'
                // logoIn.style.display = 'inline'
                this.changeParentStatus()
                phone[0].value = ''
                code[0].value = ''
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('请输入手机号或验证码!')
      }
    },
    // // 退出
    // signOut () {
    //   localStorage.removeItem('zhiHuiXinZhouwebToken')
    //   localStorage.removeItem('zhiHuiXinZhouwebUserInfo')
    //   const logoOut = document.getElementById('serchHeadLogin')
    //   const logoIn = document.getElementById('serchHeadLoginOut')
    //   logoOut.style.display = 'inline'
    //   logoIn.style.display = 'none'
    //   this.visible = false
    // },
    // 验证码
    sendcodes () {
      // 验证码
      const codeMa = document.getElementsByClassName('codebutton')
      // 手机号
      const phone = document.getElementsByClassName(
        'loingTitleZhanweiTwoPhone'
      )
      if (phone[0].value) {
        const data = {
          phone: phone[0].value,
          appName: this.$store.state.$config.appName
        }
        home.getMloginSendNote(data).then(res => {
          if (res.success) {
            // 成功,60秒倒计时
            let num = 60
            const timer = setInterval(() => {
              num--
              this.dis = true
              codeMa[0].innerText = num + '秒后重新获取'
              // this.disable
              if (num === 0) {
                this.dis = false
                codeMa[0].innerText = '发送验证码'
                clearInterval(timer)
              }
            }, 1000)
            this.$message({
              message: '验证码发送成功,请注意查收',
              type: 'success'
            })
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('请输入手机号!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myloginsData {
  ::v-deep .el-dialog__headerbtn {
    top: -40px !important;
    right: 0px !important;
    font-size: 25px !important;
  }
  ::v-deep .el-dialog__header {
    padding: 0 !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
  ::v-deep .el-icon-close:before {
    content: "\e6db";
    color: #fff;
  }
}

.loingHead {
  padding: 27px 48px 0 39px;

  .el-button:focus,
  .el-button:hover {
    color: #fff;
    border-color: $primaryColor;
    background-color: $primaryColor;
  }
  .el-input__inner:focus {
    border-color: $primaryColor;
    outline: 0;
  }
  .loingTitle {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: $primaryColor;
  }
  .loingTitleZhanwei {
    margin-top: 22px;
    background: #b96f36;
    border: 2px solid $primaryColor;
  }
  .loingTitleYanzhen {
    margin: 24px 0 8px 0;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #51565a;
    text-align: left;
  }
  .loingTitleZhanweiTwo {
    background: #b96f36;
    border: 2px solid #d8d8d8;
  }
  .loingTitlePhone {
    margin-top: 19px;
    margin-bottom: 18px;
  }
  .loingTitleZhanweiTwoCode {
    display: flex;
    .codebutton {
      padding: 0 5px !important;
      width: 110px;
      margin-left: 10px;
      color: #fff;
      background: $primaryColor;
    }
  }
  .codebuttonLogin {
    margin: 17px 0 39px 0;
    width: 100%;
    height: 36px;
    color: #fff;
    background: $primaryColor;
  }
}
.loingTitleZhanweiTwoPhone {
  margin: 19px 0 18px 0;
  outline-style: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 13px 14px;
  width: 90%;
  font-size: 14px;
  font-weight: 700;
  font-family: Microsoft YaHei;
}
.loingTitleZhanweiTwoCodeOne {
  outline-style: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 13px 14px;
  font-size: 14px;
  width: 137px;
  font-weight: 700;
  font-family: Microsoft YaHei;
}
</style>
