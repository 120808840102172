import request from '@/utils/request'

// 根据导航的标志获取导航
export function getAppOperationGetNavListBySign (params) {
  return request({
    url: '/publish/appOperation/getNavListBySign',
    method: 'post',
    params
  })
}

// 根据广告的标志获取广告
export function getAppOperationGetAdvListBySign (params) {
  if (!params || !params.sign) {
    return
  }
  return request({
    url: '/publish/appOperation/getAdvListBySign',
    method: 'post',
    params
  })
}

// 查询类型文章
export function getAppOperationSelectByMainType (data, params) {
  return request({
    url: '/publish/appOperation/selectByMainType',
    method: 'post',
    data,
    params
  })
}

// 根据栏目的标识获取文章的基本信息
export function getAppOperationGetColumnContribution (data, params) {
  data.isShowInfo = true
  return request({
    url: '/publish/appOperation/getColumnContribution',
    method: 'post',
    data,
    params
  })
}

// 获取文章的详情
export function getAppOperationGetContributionInfoById (data, params) {
  return request({
    url: '/publish/appOperation/getContributionInfoById',
    method: 'post',
    data,
    params
  })
}

// 增加文章阅读数
export function getAppOperationAddCount (params) {
  return request({
    url: '/publish/appOperation/add/count',
    method: 'post',
    params
  })
}

// 专题
// 父专题分页查询
export function getAppOperationSelectPage (params) {
  return request({
    url: '/publish/appOperation/select/page',
    method: 'post',
    params
  })
}

export function topicAttr (data, params) {
  return request({
    url: '/publish/appOperation/selectByAttr/page',
    method: 'post',
    data,
    params
  })
}

// 子专题查询
export function getAppOperationSelectChild (params) {
  return request({
    url: '/publish/appOperation/select/child',
    method: 'post',
    params
  })
}

// 专题下已添加文章查询
export function getAppOperationSelectTopicContribution (params) {
  return request({
    url: '/publish/appOperation/selectTopicContribution',
    method: 'post',
    params
  })
}

// 专题详情 publish//appOperation/select/detail
export function specialSubjectDetail (params) {
  return request({
    url: 'publish//appOperation/select/detail',
    method: 'post',
    params
  })
}

// 根据文章标题查询文章
export function getAppOperationSelectByMainTitle (params) {
  return request({
    url: '/publish/appOperation/selectByMainTitle',
    method: 'post',
    params
  })
}

// 查询网站基本信息
export function getConfigSelectSysConfByType (params) {
  return request({
    url: '/manager/app/config/selectSysConfByType',
    method: 'post',
    params
  })
}

// 会员登录
function getMloginLoginMember (data) {
  return request({
    url: '/manager/mlogin/loginMember',
    method: 'post',
    data
  })
}

// 根据TokenVO获取会员信息
function getMloginToMember (params) {
  return request({
    url: '/manager/mlogin/toMember',
    method: 'post',
    params
  })
}
// 会员发送验证码
function getMloginSendNote (params) {
  return request({
    url: '/manager/mlogin/sendNote',
    method: 'post',
    params
  })
}
// 点赞和取消点赞
function getSaveAppContributionMemberOperation (data) {
  return request({
    url: '/publish/appOperation/saveAppContributionMemberOperation',
    method: 'post',
    data
  })
}

// 新增评论内容
function getCommentAppInsert (data, params) {
  return request({
    url: '/publish/comment/app/insert',
    method: 'post',
    data,
    params
  })
}

// 根据栏目和属性查询文章列表
export function getContributionByColumnAttr (data, params) {
  return request({
    url: '/publish/appOperation/getContributionByColumnAttr',
    method: 'post',
    data,
    params
  })
}

// 查询评论列表
function getCommentAppSelect (data) {
  return request({
    url: '/publish/comment/app/select',
    method: 'post',
    data
  })
}

// 根据评论id查询该评论所有层级内容
function getCommentAppSelectDetail (params) {
  return request({
    url: '/publish/comment/app/select/detail',
    method: 'post',
    params
  })
}

// 更新渠道统计数量
function contributionCountchannel (params) {
  return request({
    url: '/publish/contributionCount/channel/update',
    method: 'post',
    params
  })
}

export function getSelectByColumnSign (data, params) {
  return request({
    url: '/publish/appOperation/selectByColumnSign',
    method: 'post',
    data,
    params
  })
}
// app中根据栏目标志查询包括子集栏目
export function getSelectColumnBySign (data, params) {
  return request({
    url: '/publish/appOperation/selectColumnBySign',
    method: 'post',
    data,
    params
  })
}

// 查询请求参数需要加密的接口
export function getAllEncrypt (data) {
  return request({
    url: '/manager/encrypt/queryAllEncrypt',
    method: 'post',
    data
  })
}

// 查询某个专题下包含某个属性的文章列表
export function selectTopicAttr (data) {
  return request({
    url: '/publish/appOperation/selectTopicAttr',
    method: 'post',
    data
  })
}

// 根据appName查询站点配置信息
export function getWebsiteConfig(data) {
  return request({
    url: 'env/work/env/queryConfig',
    method: 'post',
    data
  })
}

export default {
  getAppOperationGetNavListBySign,
  getAppOperationGetAdvListBySign,
  getAppOperationSelectByMainType,
  getAppOperationGetColumnContribution,
  getAppOperationGetContributionInfoById,
  getAppOperationAddCount,
  getAppOperationSelectPage,
  getAppOperationSelectChild,
  getAppOperationSelectTopicContribution,
  specialSubjectDetail,
  getAppOperationSelectByMainTitle,
  getConfigSelectSysConfByType,
  getMloginLoginMember,
  getMloginToMember,
  getMloginSendNote,
  getSaveAppContributionMemberOperation,
  getCommentAppInsert,
  getCommentAppSelect,
  getCommentAppSelectDetail,
  topicAttr,
  contributionCountchannel,
  getContributionByColumnAttr,
  getSelectByColumnSign,
  getSelectColumnBySign,
  getAllEncrypt,
  getWebsiteConfig,
  selectTopicAttr
}
