import Vue from 'vue'
import Vuex from 'vuex'
import config from '../config' // 页面的基本配置
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    $config: config,
    $webInfor: null,
    token: localStorage.getItem('zhiHuiXinZhouwebToken'),
    userInfo: localStorage.getItem('zhiHuiXinZhouwebUserInfo')
  },
  getters: {

  },
  mutations: {
    webInfor (state, webInfor) {
      state.$webInfor = webInfor
    },

    // 设置token
    setToken (state, token) {
      state.token = JSON.parse(token)
    },
    // 设置userInfo
    setUserInfo (state, userInfo) {
      state.userInfo = JSON.parse(userInfo)
    }
  },
  actions: {
    webInfor (context, webInfor) {
      context.commit('webInfor', webInfor)
    },
    actionSetToken (context, token) {
      context.commit('setToken', token)
    },
    actionSetUserInfo (context, userInfo) {
      context.commit('setUserInfo', userInfo)
    },
    loginOut (context) {
      localStorage.removeItem('zhiHuiXinZhouwebToken')
      localStorage.removeItem('zhiHuiXinZhouwebUserInfo')
      context.commit('setToken', JSON.stringify(''))
      context.commit('setUserInfo', JSON.stringify(undefined))
    }
  },
  modules: {
  }
})
