// 页面头部组件

export default {
  props: {
    naveType: {
      require: false,
      type: String,
      default: 'indexBack' // indexBack 大背景 、noBanner 就一个导航
    },
    search: {
      require: false,
      type: String,
      default: 'navSearch' // 查询的文职
    },
    sign: {
      require: true,
      type: String,
      default: '' // 查询的文职
    },
    redsign: {
      require: false,
      type: String,
      default: '' // 飘红广告
    },
    bannersign: {
      require: false,
      type: String,
      default: '' // banner广告
    },
    showNavigation: {
      require: false,
      type: Boolean,
      default: true // 查询的文职
    },
    adver: {
      require: false,
      type: Boolean,
      default: false // 查询的文职
    }
  }
}
