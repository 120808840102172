// 头条组件
// headLine 头条样式
// headLinenews 头条样式
export default {
  props: {
    headData: {
      require: false,
      type: Array
    },
    type: {
      type: String,
      default: '',
      require: false
    }
  }
}
