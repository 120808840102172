<template>
  <div id="app">
    <router-view
      v-wechat-title="$route.meta&&$route.meta.title?$route.meta.title:titles"
      :key="$router.fullPath"
    />
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import home from '@/api/index.js'
import { fstEncrypt } from '@/utils/cryptojs'
export default {
  data() {
    return {
      titles: ''
    }
  },
  created() {
    // 查询信息
    this.lookUpMessage()
    this.queryAllEncrypt()
    this.getUniqueCode()
    this.webLogo()
    this.getCount()
  },
  methods: {
    lookUpMessage() {
      home.getWebsiteConfig({
        appName: this.$store.state.$config.appName,
        signType: 'app',
        configIds: ['shareh5_logo_pic']
      }).then((res) => {
        if (res.success) {
          if (res.data.publicCode && res.data.publicKey) {
            let APIencipher = {
              publicCode: res.data.publicCode,
              publicKey: res.data.publicKey
            }
            APIencipher = fstEncrypt(
              JSON.stringify(APIencipher),
              this.$Global.$aesKey,
              this.$Global.$aesIv
            )
            localStorage.setItem('APIencipher', APIencipher)
          } else {
            if (localStorage.getItem('APIencipher')) {
              localStorage.removeItem('APIencipher')
            }
          }
        }
      })
    },
    getUniqueCode() {
      FingerprintJS.load().then(fp => {
        fp.get().then(result => {
          localStorage.uniCode = result.visitorId
        })
      })
    },
    queryAllEncrypt() {
      home.getAllEncrypt({
        applicationName: '',
        requestUrl: ''
      }).then((res) => {
        if (res.success) {
          const arr = []
          res.data.forEach(e => {
            const arr1 = e.applicationName.split('-')
            const resultStr = '/' + arr1[1] + e.requestUrl
            arr.push(resultStr)
          })
          localStorage.setItem('localEncrypt', JSON.stringify(arr))
        }
      })
    },

    // 网站logo
    webLogo() {
      home
        .getConfigSelectSysConfByType({
          configType: 'web',
          appName: this.$store.state.$config.appName
        })
        .then((res) => {
          if (res.success) {
            this.createedMeta('keywords', res.data.WEB_SEO_KEYWORD)
            this.createedMeta('description', res.data.WEB_SEO_SUMMARY)
            this.$store.dispatch('webInfor', res.data)
            this.titles = res.data.WEB_NAME
          }
        })
    },
    createedMeta(name, content) {
      const meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      document.getElementsByTagName('head')[0].appendChild(meta)
    },
    // 更新渠道统计数量
    getCount() {
      const data = {
        sign: 'WEBCOUNT',
        appName: this.$store.state.$config.appName
      }
      home.contributionCountchannel(data).then((res) => {
        if (res.success) {
          // this.StatisticsNum = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  // position: relative;
  // z-index: -2;
}
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.boxs {
  box-sizing: border-box;
}
.oneline {
  white-space: nowrap; //禁止换行
  overflow: hidden;
  text-overflow: ellipsis;
}
.gap {
  width: 100%;
  height: 30px;
}
</style>
