<template>
  <div class="home" :class="{'huibaiSty':huibaiyemianData}">
    <!-- {{erweima}} -->
    <div class="erBoxdatas">
      <img
        class="erweiBox"
        @click="adverToPage(item)"
        v-for="(item,index) in erweima"
        :key="index"
        :src="item.advertisementImage | uploadFilters"
        alt
      />
      <div @click="toTopClick(50)" id="toTop" v-show="toTopBut">
        <img class="toTopbox" src="../assets/toTop.png" alt />
      </div>
    </div>
    <headWeb sign="WZSYDH" :adver="true" redsign="WZSBBJ" bannersign="WZT" @indexWidth="bottomMin=true" :headerType="'xuanzhou'" />
    <div :class="{backheadcon:backheadcon}">
      <div class="moreAdvider">
        <div class="nineBox">
            <img
            class="beforeNine"
            @click="adverToPageXuan(item)"
            v-for="(item,index) in adverData"
            :key="index"
            :src="item.advertisementImage | uploadFilters"
            alt
            />
        </div>
        <img
          class="myname"
          @click="adverToPageXuan(item)"
          v-for="(item,index) in longList"
          :key="index"
          :src="item.advertisementImage | uploadFilters"
          alt
        />
      </div>
      <!-- 头条新闻 -->
      <headLine v-if="headData.length" :headData="headData" :type="'xuanzhou'" />
      <!-- <headLineNews /> -->
      <!-- <div class="gap"></div> -->
      <div class="tuixin">
        <recommendBox titleType="shadeBo" />
        <div class="right">
          <div class="right-top" v-if="watchList.length>0" @click="adverToPageXuan(watchList[0])">
            <img :src="watchList[0].advertisementImage | uploadFilters" alt="">
          </div>
          <div class="newsListcon">
            <bigTitle :mytitles="allCon.tuijian" lineType="smallline">
              <div v-for="(item,index) in allCon.tuijian" :slot="[item.id]" :key="index">
                <indexNewsList type="xuanzhou" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
              </div>
            </bigTitle>
          </div>
        </div>
      </div>
      <div class="gap"></div>
      <!-- 中间六区域开始 -->
      <div class="middleSix">
        <!-- 左边四区域：重点推荐、今日宣州、区直动态、乡镇风采 -->
        <div class="leftSix">
          <div class="public-box">
            <div class="regionBlcok" style="margin-right:15px">
              <bigTitle :numHeight="44" :mytitles="allCon.zonghe" lineType="smallline">
                <div v-for="(item,index) in allCon.zonghe" :slot="[item.id]" :key="index">
                  <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
                </div>
              </bigTitle>
            </div>
            <div class="regionBlcok">
              <bigTitle :numHeight="44" :mytitles="allCon.meiti" lineType="smallline">
                <div v-for="(item,index) in allCon.meiti" :slot="[item.id]" :key="index">
                  <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
                </div>
              </bigTitle>
            </div>
          </div>
          <div class="middleImg">
            <template v-if="adviseList.length>0 && adviseList[0]">
              <img @click="adverToPageXuan(adviseList[0])" :src="adviseList[0].advertisementImage | uploadFilters" alt="">
            </template>
          </div>
          <div class="public-box">
            <div class="regionBlcok" style="margin-right:15px">
              <bigTitle :numHeight="44" :mytitles="allCon.tongbian" lineType="smallline">
                <div v-for="(item,index) in allCon.tongbian" :slot="[item.id]" :key="index">
                  <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
                </div>
              </bigTitle>
            </div>
            <div class="regionBlcok">
              <bigTitle :numHeight="44" :mytitles="allCon.xuanchuanpian" lineType="smallline">
                <div v-for="(item,index) in allCon.xuanchuanpian" :slot="[item.id]" :key="index">
                  <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
                </div>
              </bigTitle>
            </div>
          </div>
        </div>
        <!-- 右侧俩图片 -->
        <div class="rightParent">
          <div class="qrcode">
            <template v-if="codeList.length>0">
              <img :src="codeList[0].advertisementImage | uploadFilters" alt="">
            </template>
          </div>
          <div class="xuanzhouNews" v-if="allCon.xinwenXZ.length>0">
            <p>{{allCon.xinwenXZ[0].navigationName}}</p>
            <img @click="guangbo(allCon.xinwenXZ[0])" :src="allCon.xinwenXZ[0].navigationCover | uploadFilters" alt="">
          </div>
        </div>
      </div>
      <!-- 中间六区域结束 -->
      <div class="bannerImg">
        <template v-if="adviseList.length>0 && adviseList[1]">
          <img @click="adverToPageXuan(adviseList[1])" :src="adviseList[1].advertisementImage | uploadFilters" alt="">
        </template>
      </div>
      <!-- 中间三个相同区域 -->
      <div class="middleThree">
        <div class="regionBlcok" style="margin-right:15px">
          <bigTitle :numHeight="44" :mytitles="allCon.xinwen" lineType="smallline">
            <div v-for="(item,index) in allCon.xinwen" :slot="[item.id]" :key="index">
              <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
            </div>
          </bigTitle>
        </div>
        <div class="regionBlcok" style="margin-right:15px">
          <bigTitle :numHeight="44" :mytitles="allCon.renwen" lineType="smallline">
            <div v-for="(item,index) in allCon.renwen" :slot="[item.id]" :key="index">
              <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
            </div>
          </bigTitle>
        </div>
        <div class="regionBlcok">
          <bigTitle :numHeight="44" :mytitles="allCon.gonggao" lineType="smallline">
            <div v-for="(item,index) in allCon.gonggao" :slot="[item.id]" :key="index">
              <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
            </div>
          </bigTitle>
        </div>
      </div>
      <!-- 中间三个相同区域结束 -->
      <!-- 中间三个相同区域开始包含便民服务 -->
      <div class="middleThree">
        <div class="regionBlcok" style="margin-right:15px">
          <bigTitle :numHeight="44" :mytitles="allCon.dajiang" lineType="smallline">
            <div v-for="(item,index) in allCon.dajiang" :slot="[item.id]" :key="index">
              <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
            </div>
          </bigTitle>
        </div>
        <div class="regionBlcok" style="margin-right:15px">
          <bigTitle :numHeight="44" :mytitles="allCon.jiangkang" lineType="smallline">
            <div v-for="(item,index) in allCon.jiangkang" :slot="[item.id]" :key="index">
              <indexNewsList type="xuanzhouList" fontSize="16px" v-for="(sonit,sonin) in item.datas" :items="sonit" :key="sonin" />
            </div>
          </bigTitle>
        </div>
        <div class="regionBlcok" style="border: 1px solid #f0f0f0;" v-if="allCon.bianmin.length>0">
          <p class="bianTitle">{{allCon.bianmin[0].navigationName}}</p>
          <div class="bianList">
            <div class="bianListOne" v-for="(sonit,sonin) in allCon.bianmin[0].datas" :key="sonin" @click="adverToPage(sonit)">
              <div class="img">
                <img :src="sonit.advertisementImage | uploadFilters" alt="">
              </div>
              <p>{{sonit.advertisementName}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间三个相同区域结束 -->
      <div class="bannerImg">
        <template v-if="adviseList.length>0 && adviseList[2]">
          <img @click="adverToPageXuan(adviseList[2])" :src="adviseList[2].advertisementImage | uploadFilters" alt="">
        </template>
      </div>
      <div class="xuanzhouSwiper">
        <shiJueSwiper v-if="allCon.shijueXuanzhou.length>0&&allCon.shijueXuanzhou[0].datas" :specialData="allCon.shijueXuanzhou" />
      </div>
      <div class="bannerImg">
        <template v-if="adviseList.length>0 && adviseList[3]">
          <img @click="adverToPageXuan(adviseList[3])" :src="adviseList[3].advertisementImage | uploadFilters" alt="">
        </template>
      </div>
      <!-- 美丽宣州 -->
      <div class="xuanzhouSwiper">
        <shiJueSwiper :iconShow="false" v-if="allCon.meiliXuanzhou.length>0&&allCon.meiliXuanzhou[0].datas" :specialData="allCon.meiliXuanzhou" />
      </div>
      <!-- 友情链接 -->
      <div class="friendHref" v-if="allCon.youqing.length>0">
        <p class="friendTitle">{{allCon.youqing[0].navigationName}}</p>
        <div class="friendList">
          <div class="friendListOne" v-for="(sonit,sonin) in allCon.youqing[0].datas" :key="sonin" @click="adverToPage(sonit)">
            <p>{{sonit.advertisementName}}</p>
          </div>
        </div>
      </div>
    </div>
    <footWeb :class="{minW:bottomMin}" />
    <!-- 飘窗 -->
    <bayWindow></bayWindow>
  </div>
</template>

<script>
import headLine from '@/components/headLine/headLine'
import recommendBox from '@/components/recommend/recommend'
import bigTitle from '@/components/xuanZhou/xuanzhouTitles/bigTitle'
import indexNewsList from '@/components/indexNewsList/indexNewsList'
import footWeb from '@/components/footWeb/footWeb'
import headWeb from '@/components/headWeb/headWeb'
import home from '@/api/index.js'
import { adverTopage, jumpTopage, recommentJump } from '@/utils/toDetail'
import bayWindow from './bayWindow/bayWindow'
import shiJueSwiper from '@/components/xuanZhou/shiJueXuanZhou'
export default {
  name: 'HomeView',
  components: {
    headLine,
    recommendBox,
    bigTitle,
    indexNewsList,
    footWeb,
    headWeb,
    bayWindow,
    shiJueSwiper
  },
  data() {
    return {
      huibaiyemianData: false,
      zhuantiAdvs: [], // 专题广告
      adverData: [], // 广告数据
      headData: [], // 头条数据
      allCon: {
        tuijian: [], // 推荐的数据/外媒看宣州
        zonghe: [], // 综合新闻/重点推荐
        meiti: [], // 媒体/今日宣州
        tongbian: [], // 通知公告、便民信息/区直动态
        xuanchuanpian: [], // 郎溪宣传片/乡镇风采
        xinwen: [], // 郎溪新闻/深度报道
        renwen: [], // 人文郎溪/人文宣州
        gonggao: [], // 公示公告
        dajiang: [], // 大江南北
        jiangkang: [], // 健康教体
        shijueXuanzhou: [], // 视觉宣州
        meiliXuanzhou: [], // 美丽宣州
        youqing: [], // 友情链接
        xinwenXZ: [], // 宣州新闻
        bianmin: [] // 便民服务

      },
      bottomMin: false,
      backheadcon: true,
      erweima: [],
      toTopBut: false,
      adviseList: [], // 首页广告
      codeList: [], // 二维码广告
      longList: [], // 网站长广告
      watchList: [] // 观点视角广告
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  created() {
    const that = this
    window.addEventListener('scroll', function (e) {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 600) {
        that.toTopBut = true
      } else {
        that.toTopBut = false
      }
    })

    this.adverDataLink('WZLGG') // 这里是获取列广告
    this.adverDataLink('WZLGGCGG', 'longList') // 这里是获取长列广告
    this.adverDataLink('WZGDSJ', 'watchList') // 这里是获取观点视角
    this.adverDataLink('WZSYGG', 'indexList') // 这里是获取首页广告
    this.adverDataLink('WZJRXZEWM', 'code') // 这里是获取二维码广告
    this.HeadlinesList(['TT'], 3, true, '', 'headData') // 这里是获取头条新闻
    this.mycontentNav()
    this.teshuZhuanti() // 获取专题
    this.huibaiYemian() // 页面灰白
    this.sideTipscon() // 二维码下载
  },
  methods: {

    toTopClick(i) {
      let timer
      // 参数i表示间隔的幅度大小，以此来控制速度
      document.documentElement.scrollTop -= i
      if (document.documentElement.scrollTop > 0) {
        timer = setTimeout(() => this.toTopClick(i))
      } else {
        if (timer) {
          clearTimeout(timer)
        }
      }
    },
    myscrollData (da) {
    },
    adverToPage(item) {
      adverTopage(item)
    },
    adverToPageXuan(val) {
      if (!val.advertisementIink && val.dateUrl) {
        const { href } = this.$router.resolve({
          path: val.dateUrl
        })
        window.open(href)
        // this.$router.push({ path: val.dateUrl })
      } else {
        this.adverToPage(val)
      }
    },
    // 头条新闻
    HeadlinesList(arrList, number, flage, listTitle, name, sta) {
      home
        .getAppOperationGetColumnContribution({
          appName: this.configWeb.appName,
          attrSignList: arrList,
          rows: number,
          isShowInfo: flage,
          columnSign: listTitle
        })
        .then((res) => {
          if (res.success) {
            if (sta) {
              this.$set(name, 'datas', res.data.items)
            } else {
              this[name] = res.data.items
              // console.log('我的头条数据', res, this.headData)
            }
          }
        })
    },
    huibaiYemian() {
      this.adverDataLink('YMHB', 'yemian')
    },
    // 侧边栏二维码
    sideTipscon() {
      this.adverDataLink('ZSEWM', 'erweima')
    },
    // 广告的接口
    adverDataLink(sign, its) {
      home
        .getAppOperationGetAdvListBySign({
          sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (its === 'erweima') {
              this.erweima = res.data
            } else if (its === 'yemian') {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].advertisementSign === 'WYHB') {
                  this.huibaiyemianData = true
                  break
                }
              }
            } else if (its === 'indexList') {
              this.adviseList = res.data
            } else if (its === 'code') {
              this.codeList = res.data
            } else if (its === 'longList') {
              this.longList = res.data
            } else if (its === 'watchList') {
              this.watchList = res.data
            } else if (its) {
              this.$set(its, 'datas', res.data)
              // console.log(its, '666')
            } else {
              this.adverData = res.data
            }
          }
        })
    },
    // 导航接口
    mycontentNav() {
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: 'WZSYNR',
          appName: this.configWeb.appName
        })
        .then(async (res) => {
          if (res.success) {
            res.data[0].info.map(it => {
              if (it.navigationSign === 'ZYZZ' || it.navigationSign === 'SZYW') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.tuijian.push(it)
              }
              if (it.navigationSign === 'ZhongDianTuiJian') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.zonghe.push(it)
              }
              if (it.navigationSign === 'XuanZhouXinWen') {
                // this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.xinwenXZ.push(it)
              }
              if (it.navigationSign === 'JinRiXuanZhou') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.meiti.push(it)
              }
              if (it.navigationSign === 'QuZhiDongTai') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.tongbian.push(it)
              }
              if (it.navigationSign === 'XiangZhenFengCai') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.xuanchuanpian.push(it)
              }
              if (it.navigationSign === 'ShenDuBaoDao') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.xinwen.push(it)
              }
              if (it.navigationSign === 'RenWenXuanZhou') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.renwen.push(it)
              }
              if (it.navigationSign === 'GongShiGongGao') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.gonggao.push(it)
              }
              if (it.navigationSign === 'DaJiangNanBei') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.dajiang.push(it)
              }
              if (it.navigationSign === 'JianKangJiaoTi') {
                this.HeadlinesList([], 7, true, it.columnSign, it, true)
                this.allCon.jiangkang.push(it)
              }
              if (it.navigationSign === 'ShiJueXuanZhou') {
                this.HeadlinesList([], 20, true, it.columnSign, it, true)
                this.allCon.shijueXuanzhou.push(it)
              }
              if (it.navigationSign === 'MeiLiXuanZhou') {
                this.HeadlinesList([], 4, true, it.columnSign, it, true)
                this.allCon.meiliXuanzhou.push(it)
              }
              if (it.navigationSign === 'BianMinFuWu') {
                this.adverDataLink(it.navigationSign, it)
                this.allCon.bianmin.push(it)
              }
              if (it.navigationSign === 'YouQingLianJie') {
                this.adverDataLink(it.navigationSign, it)
                this.allCon.youqing.push(it)
              }

              return it
            })
          }
        })
      // console.log(this.allCon, '===this.allCon')
    },
    // 便民服务点击跳转
    bianminClick(item) {
      adverTopage(item)
    },
    // 广播跳转
    guangbo(item) {
      jumpTopage(item)
    },
    // 推荐广告的点击事件
    tuijianClick(item) {
      recommentJump(item, '/newslist')
    },
    // 首页广告跳转
    goToadvise(val) {
      console.log(val, '66666')
      if (val.advertisementIink) {
        window.open(val.advertisementIink)
      } else if (val.dateUrl) {
        const href = window.location.href + val.dateUrl
        window.open(href)
        // console.log(window.location.href, '2122')
        // this.$router.push({ path: val.dateUrl })
      }
    },
    teshuZhuanti() {
      home
        .topicAttr({

        }, {
          attrSign: 'TJ',
          page: 1,
          pageSize: 2,
          appName: this.configWeb.appName

        })
        .then(async (res) => {
          if (res.success) {
            this.zhuantiAdvs = res.data.items
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.erBoxdatas {
  position: fixed;
  right: 0px;
  top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  .erweiBox {
    width: 73px;
    height: auto;
  }
  .toTopbox {
    width: 57px;
    height: 57px;
    margin-top: 20px;
    cursor: pointer;
  }
}
.huibaiSty {
  filter: grayscale(100%);
}
.home {
  position: relative;
  .minW {
    min-width: calc($primaryWidth + 20px);
  }
}
.moreAdvider {
  width: $primaryWidth;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  .nineBox{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .beforeNine{
        width: 32.5%;
        margin-right: 1.25%;
        margin-bottom: 15px;
        height: 60px;
        cursor: pointer;
    }
    .beforeNine:nth-child(3n){
        margin-right: 0;
    }
  }
  .myname {
    cursor: pointer;
    width: 100%;
    height: 60px;
    // height: auto;
    margin-bottom: 4px;
    // &:first-child {
    //   margin-top: 20px;
    // }
  }
}
.backheadcon {
  width: calc($primaryWidth + 20px);
  background-color: #fff;
  margin: 0px auto;
}
.tuixin {
  width: $primaryWidth;
  height: 466px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .right {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    overflow: hidden;
    .right-top {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      cursor: pointer;
      .ztImage {
        height: 61px;
        width: 210px;
        object-fit: fill;
        margin: auto 10px;
      }
    }
    .newsListcon {
      margin-left: 30px;
      box-sizing: border-box;
      margin-top: 15px;
      width: 440px;
      height: 378px;
    }
  }
}
.middleSix{
  width: $primaryWidth;
  display: flex;
  justify-content: space-between;
  margin: 15px auto 0;
  overflow: hidden;
  // border: 1px solid red;
  .leftSix{
    display: flex;
    flex-direction: column;
    .public-box{
      display: flex;
      .regionBlcok{
        width: 404px;
        height: 356px;
      }
    }
    .middleImg{
      width: 828px;
      height: 60px;
      margin: 15px auto;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .rightParent{
    display: flex;
    flex-direction: column;
    .qrcode{
      width: 358px;
      height: 506px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .xuanzhouNews{
      margin-top: 15px;
      width: 358px;
      border: 1px solid #f1f1f1;
      p{
        width: 100%;
        height: 58px;
        line-height: 58px;
        margin: auto;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
      img{
        width: 306px;
        height: 178px;
        margin: 0 26px 20px;
        cursor: pointer;
      }
    }
  }
}
.bannerImg{
  width: $primaryWidth;
  margin: 15px auto 0;
  img{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.middleThree{
  width: $primaryWidth;
  display: flex;
  justify-content: space-between;
  margin: 15px auto 0;
  overflow: hidden;
  .regionBlcok{
    width: 404px;
    height: 356px;
    .bianTitle{
      height: 58px;
      line-height: 58px;
      font-size: 24px;
      font-weight: bold;
      position: relative;
      padding: 0 20px;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;
    }
    .bianList{
      display: flex;
      flex-wrap: wrap;
      padding: 8px 21px 0;
      .bianListOne{
        width: 84px;
        height: 88px;
        margin: 0 10px 8px;
        cursor: pointer;
        .img{
          width: 64px;
          height: 64px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        p{
          height: 22px;
          line-height: 22px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.xuanzhouSwiper{
  width: $primaryWidth;
  margin: 15px auto 0;
}
.friendHref{
  width: $primaryWidth;
  margin: 15px auto;
  border: 1px solid #f0f0f0;
  .friendTitle{
    height: 58px;
    line-height: 58px;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
  }
  .friendList{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    .friendListOne{
      width: 170px;
      line-height: 28px;
      font-size: 14px;
      text-align: center;
      p{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}

.mynewsCon {
  width: $primaryWidth;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  height: 358px;
  overflow: hidden;
  .sonlistD {
    width: 31%;
  }
}
.imgboxcon {
  width: $primaryWidth;
  margin: 0px auto;
  margin-bottom: 10px;
  .imins {
    width: 100%;
    height: auto;
  }
}
.mediabox {
  width: $primaryWidth;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  height: 280px;
  overflow: hidden;
  .cardBox {
    width: 280px;
    height: 100%;
    .xuanchuan {
      width: 100%;
      height: calc(280px - 54px);
      // background: yellow;
    }
  }
  .videoBox {
    width: 320px;
    height: 100%;
    .xuanchuan {
      width: 100%;
      height: calc(280px - 54px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      .vid {
        width: 156px;
        height: 109px;
      }
    }
  }
  .audiobox {
    width: 200px;
  }
}
.bianmin {
  width: $primaryWidth;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  .bianmingItem {
    cursor: pointer;
    width: 220px;
    height: 45px;
    border: 1px solid #999;
    margin-bottom: 15px;
    margin-right: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    box-sizing: border-box;
    &:nth-child(5n + 5) {
      margin-right: 0px;
    }
    .bianmIcon {
      width: 25px;
      height: 25px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
}
.youqing {
  width: $primaryWidth;
  margin: 0px auto;
  .mylinkdata {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .linIm {
      cursor: pointer;
      margin-right: 14px;
      font-size: 14px;
      margin-bottom: 14px;
    }
  }
}
</style>
