
import config from '../config' // 页面的基本配置
const link = config.baseUrl
const uploadFilters = (url) => {
  if (url) {
    if (
      url.indexOf(link) === -1 &&
      url.indexOf('http://') === -1 &&
      url.indexOf('https://') === -1
    ) {
      // console.log(link, url)
      return `${link}media${url}`
    } else {
      return url
    }
  }
}

const uploadFilter = (url) => {
  const img = []
  for (let i = 0; i < url.length; i++) {
    if (
      url[i].indexOf(link) === -1 &&
      url[i].indexOf('http://') === -1 &&
      url[i].indexOf('https://') === -1
    ) {
      img.push(`${link}media${url[i]}`)
    } else {
      img.push(url[i])
    }
  }
  return img
}

const uploadFilterMaritx = (url) => {
  const img = []
  for (let i = 0; i < url.length; i++) {
    if (url[i].url.indexOf(link) === -1) {
      img.push({ url: `${link}media${url[i].url}` })
    } else {
      img.push({ url: url[i].url })
    }
  }
  return img
}

const uploadFilterbase = (url) => {
  if (url) {
    if (url.indexOf('data:image') === -1 && url.indexOf('base64') === -1) {
      if (url.indexOf(link) === -1) {
        return `${link}media${url}`
      } else {
        return url
      }
    } else {
      return url
    }
  }
}

export default {
  uploadFilters,
  uploadFilter,
  uploadFilterMaritx,
  uploadFilterbase
}
