// 推荐轮播图
export default {
  props: {
    titleType: {
      require: false,
      type: String,
      default: 'back' // back 全黑加透明度  shadeBo 渐变黑
    },
    // 专题id
    specialId: {
      require: false,
      type: Number,
      default: 0
    },
    // 没数据时该区域还显不显示
    nodata: {
      require: false,
      default: false,
      type: Boolean
    }
  }
}
