<template>
  <div class="multipleSlider">
    <p class="titleSwiper">{{specialData[0].navigationName}}</p>
    <!-- 轮播图 -->
    <div class="slideBox">
      <div v-if="iconShow" class="elIconLeft icondata" @click="$refs.slider.$emit('slidePre')">
        <img src="@/assets/xuanzhou/leftSwiper.png" alt="">
      </div>
      <slider class="slider boxs" ref="slider" :options="options">
        <slideritem class="slideritemBox boxs" v-for="(item,index) in swiperList" :key="index">
          <div class="slideImg" @click="imgLinkTo(item)">
            <!-- <img  v-if="item.topicCover&&JSON.parse(item.topicCover).length" :src="JSON.parse(item.cover)[0]|uploadFilters" alt=""> -->
            <img
              v-if="item.cover&&JSON.parse(item.cover).length"
              :src="JSON.parse(item.cover)[0] | uploadFilters"
              alt
            />
            <img v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
            <img v-if="item.contributionType === 'AUDIO_VIDEO_TYPE'" class="videoPlay" src="@/assets/bf.png" alt="">
            <p class="videoTitle">{{item.mainTitle}}</p>
          </div>
        </slideritem>
      </slider>
      <div v-if="iconShow" class="elIconRight icondata" @click="$refs.slider.$emit('slideNext')">
        <img src="@/assets/xuanzhou/rightSwiper.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import { toDetails } from '../../utils/toDetail'
export default {
  components: { slider, slideritem },
  props: {
    specialData: {
      require: true,
      type: Array,
      default: () => []
    },
    iconShow: {
      require: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      appName: this.$store.state.$config.appName,
      options: {
        currentPage: 0,
        infinite: 4,
        slidesToScroll: 1,
        loop: false,
        pagination: false,
        speed: 300,
        loopedSlides: 4
        // autoplay: 3000,
        // freeze: false
      }
    //   specialData: []
    }
  },
  computed: {
    swiperList() {
      if (this.specialData[0].datas && this.specialData[0].datas.length > 0) {
        return this.specialData[0].datas
      } else {
        return []
      }
    }
  },
  created() {

  },
  methods: {
    // 图片跳转
    imgLinkTo(val) {
      toDetails(val)
    }
  }
}
</script>

<style  lang="scss" scoped>
.multipleSlider {
  width: $primaryWidth;
  margin: 0px auto;
  border: 1px solid #f0f0f0;
  .titleSwiper{
    height: 51px;
    line-height: 51px;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin: auto;
  }
  .slideBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    position: relative;
    .slider {
    //   width: calc(100% - 72px);
      overflow: hidden;
      padding: 0px 12px;
      box-sizing: border-box;
    }
    .slideritemBox {
      width: 280px;
      height: 175px;
      margin-right: 20px;
      .slideImg {
        width: 100%;
        height: 175px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: all 0.5s;
        position: relative;
        // &:hover {
        //   transform: scale(1.1, 1.1);
        // }
        img {
          width: 100%;
          height: 100%;
        }
        .videoPlay{
            width: 35px;
            height: 35px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -17.5px;
            margin-left: -17.5px;
        }
        .videoTitle{
            width: 100%;
            height: 30px;
            line-height: 30px;
            position: absolute;
            left: 0px;
            bottom: 0px;
            color: #FFF;
            padding: 0 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: rgba(0,0,0,0.4);
            text-align: left;
            font-size: 14px;
            box-sizing: border-box;
        }
      }
    }
    .icondata {
        width: 22px;
        height: 38px;
        position: absolute;
        top: 68px;
        left: -50px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .elIconRight{
        right: -50px;
        left: unset;
    }
  }
}
</style>
