<template>
  <div class="headLine boxs" @click="myClick" :class="{'XZclass':type === 'xuanzhou'}">
    <div class="heSign">
      <img src="@/assets/xuanzhou/new.png" alt="" v-if="type === 'xuanzhou'">
      <i v-else class="small">今日头条</i>
    </div>
    <div class="heTitleS oneline">{{headData[0].mainTitle}}</div>
    <div class="heScri oneline" v-if="configWeb.appName !== 'xuanzhourm'">
      <div v-for="(i,j) in headData[0].contributionResourcesDTOS" :key="j">
        <p v-if="i.resourcesType === 'TEXT_IMAGE'" class="oneline">
          <!-- <span v-html="i.content"></span> -->
          {{ i.content|conChan }}
        </p>
      </div>
    </div>
    <div class="heScri" v-else>
      <p v-if="headData[1]" style="text-align:right" @click.stop="twoClick(headData[1])">{{headData[1].mainTitle}}</p>
      <span>|</span>
      <p v-if="headData[2]" style="text-align:left" @click.stop="twoClick(headData[2])">{{headData[2].mainTitle}}</p>
    </div>
  </div>
</template>

<script>
import props from './props.js'
import { toDetails } from '@/utils/toDetail'
export default {
  mixins: [props],
  // name: 'headLine'
  filters: {
    conChan(data) {
      return data.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  methods: {
    myClick() {
      toDetails(this.headData[0])
    },
    twoClick(val) {
      toDetails(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.headLine {
  width: $primaryWidth;
  height: 132px;
  margin: 0px auto;
  position: relative;
  padding: 30px 122px;
  cursor: pointer;
  .heSign {
    width: 92px;
    background-color: #ff0000;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    top: 30px;
    left: 0px;
    .small {
      color: #fff;
      font-weight: bold;
      line-height: 34px;
    }
  }
  .heTitleS {
    font-size: 30px;
    text-align: center;
  }
  .heScri {
    font-size: 14px;
    color: #959595;
    text-align: center;
    margin-top: 12px;
  }
}
.XZclass{
  height: 100px;
  margin-top: 15px;
  padding: 15px;
  .heSign{
    background-color: $primaryColor;
    height: 100%;
    width: 222px;
    top: 0;
  }
  .heTitleS,.heScri{
    margin-left: 240px;
  }
  .heScri{
    display: flex;
    width: 80%;
    justify-content: space-between;
    p{
      width: 48%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
