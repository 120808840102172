<template>
  <div class="bigSideBox">
    <div
      class="bigHead boxs"
      :class="{bigHeadline:lineType=='smallline',nolineHead:lineType=='smallnoline'}"
    >
      <div class="myboxdata">
        <div
          class="titles"
          @mouseenter="mouChange(index)"
          @click="clicChange(index)"
          v-for="(item,index) in mytitles"
          :key="index"
        >
          <span>{{item&&item.navigationName?item.navigationName:''}}</span>
          <div class="icons" v-show="!(lineType=='smallline')&&choosedIndex==index"></div>
        </div>
      </div>
      <!-- <div
        class="morecon"
        v-if="more||(mytitles.length&&mytitles[choosedIndex]&&(mytitles[choosedIndex].dataLink||mytitles[choosedIndex].otherLink))"
        :class="{noblock:lineType=='smallline'}"
        @click="toOtherData"
      >
        <span>更多</span>
        <i class="el-icon-d-arrow-right" v-if="lineType=='pig'"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div> -->
    </div>
    <!-- 标题底下的内容啊 -->
    <div class="hohecon">
      <div v-for="(item,index) in mytitles" :key="index">
        <div v-show="choosedIndex==index">
          <slot :name="item.id" v-if="item&&item.id"></slot>
        </div>
      </div>
    </div>
    <div class="moreInfo" v-if="showMore" @click="toOtherData" :style="{height:numHeight+'px',lineHeight:numHeight+'px'}">
      查看更多>>
    </div>
  </div>
</template>

<script>
import props from './props.js'
export default {
  mixins: [props],
  data() {
    return {
      choosedIndex: 0,
      showMore: false
    }
  },
  watch: {
    mytitles(newVal, oldVal) {
      // console.log(newVal, oldVal, '0000000000000')
      if (newVal && newVal.length > 0 && newVal[0].datas && newVal[0].datas.length >= 7) {
        this.showMore = true
        // console.log('45666666666666666666666666666666')
      }
    }
  },
  methods: {
    mouChange(index) {
      if (this.changeType === 'hover') {
        this.choosedIndex = index
      }
    },
    clicChange(index) {
      if (this.changeType === 'click') {
        this.choosedIndex = index
      }
    },

    // 跳转到其他页面
    toOtherData() {
      const item = this.mytitles[this.choosedIndex]
      if (item.columnId && item.wenming) { // 文明实践列表跳转
        this.$router.push({
          path: '/newsListWenMing',
          query: {
            columnId: item.columnId
          }
        })
      } else if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink !== '') {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }

        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bigSideBox {
  width: 100%;
  height: 100%;
  // height: 378px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  .hohecon {
    width: 100%;
    margin-top: 14px;
  }
  .moreInfo{
    font-size: 14px;
    text-align: center;
    border-top: 1px solid #f1f1f1;
    cursor: pointer;
    color: #666;
  }
}
.bigHead {
  width: 100%;
  height: 58px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .myboxdata {
    // display: flex;
    width: 100%;
  }
  .titles {
    height: 58px;
    line-height: 58px;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .icons {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 5px solid $primaryColor;
    }
  }
  .tiActive {
    background: $primaryColor;
    color: #fff;
  }
  .smallline {
    background: #fff;
    color: #333;
    padding: 0 0px;
    margin-left: 20px;
    margin-right: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
  .tiActive.smallline {
    font-weight: bold;
    color: $primaryColor;
    border-bottom: 2px solid $primaryColor;
  }
  .morecon {
    font-size: 14px;
    color: #858585;
    cursor: pointer;
  }
}
.bigHeadline {
  border-bottom: 1px solid #f0f0f0;
}
.nolineHead {
  border-bottom: none;
}
</style>
